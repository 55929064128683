@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap');
@font-face {
    font-family: "Interplay";
    src: url(webpack:///mini-css-extract-plugin//_next/static/media/Nexa-Heavy.64ee4808.ttf);
    font-weight: bolder;
  font-style: normal;
  font-display: block;
}


.layer {
    font: bold 2.5em "Interplay", "Trebuchet MS", Helvetica, sans-serif;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 
      0 0 3px rgba(255, 255, 255, 0.8),  
      0 0 8px rgba(255, 255, 255, 0.6),
      2px 2px 6px rgba(0, 0, 0, 0.7),
      4px 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  /* .bannerBonus{
    position: absolute;
    right: -100%;
  } */
.dialog-content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20vh;
    left: 44vw;
    width: auto;
    height: auto;
    overflow: hidden;
    background-color: #002244;
    padding: 20px;
    border-radius: 10px;
  }
  
  .spin-wheel-background {
    position: absolute;
    width: 200%;
    height: 200%;
    background: conic-gradient(
      from 0deg,
      #151c30,
      #1d2a51,
      #002244,
      #1d2a51,
      #151c30,
      #1d2a51,
      #002244,
      #1d2a51,
      #151c30,
      #1d2a51,
      #002244,
      #1d2a51,
      #151c30
    );
    animation: spin 10s linear infinite;
    z-index: 1; 
  }
  
  .content-overlay {
    position: relative;
    z-index: 2; 
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
@media (max-width: 768px) {
    .dialog-content {
        left: 20%;
        top: 25vh;
        width: 60%;
        height: 250px;
        padding: 10px;
        .layer{
            font-size: 1.2em;
        }
        img{
            width: 100px;
            height: 100px;
            margin-top: 7px;
            margin-bottom: 7px;
        }
        .content-overlay p{
            font-size: 0.8em;
            margin-bottom: 6px;
        }
        button{
            font-size: 0.8em;
            height: 30px;
        }
    }
    .spin-wheel-background {
        width: 200%;
        height: 200%;
    }
    
}
@media (min-width: 680px) {
    .bannerBonus{
      position: absolute;
      right: -30%;
    }
}
@media (max-width: 480px) {
    .dialog-content {
        left: 20vw;
    }
    
}
